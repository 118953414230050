import Vue from "vue";
import type { Route } from "vue-router";

import { login as loginRoute, ocorrenciaAnonima as ocorrenciaAnonimaRoute } from "@/pages/config";
import auth, { checkToken } from "@/services/auth";

import App from "./App.vue";
import router from "./router";
import "./components";
import "./styles/main.scss";

Vue.config.productionTip = false;

const strategies = Vue.config.optionMergeStrategies;
strategies.processScreen = strategies.methods;

router.beforeEach(async (to: Route, from: Route, next: () => void) => {
  if (to.name !== loginRoute.name && to.name !== ocorrenciaAnonimaRoute.name) {
    await checkToken();
    if (auth.value === null) {
      return next({
        name: loginRoute.name,
        query: { redirectTo: to.fullPath },
      });
    }
  }

  return next(true);
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
